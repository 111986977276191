/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
import { convertDate, toggleLanguage } from '@/helper'
import useContentGlobal from '@/hooks/useContentGlobal'
import useSavaSessionKey from '@/hooks/useSavaSessionKey'
import { booking } from '@/services/booking'
import { sendLog } from '@/services/sendLog'
import { QRCode } from 'antd'
import { toPng } from 'html-to-image'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { LoadScreen, PopupNoti } from '../common'

export const CheckoutStep3 = () => {
  const ticketRef = useRef<HTMLDivElement>(null)
  const { query } = useRouter()
  const router = useRouter()
  const { session_key } = useSavaSessionKey()
  const [isOpenCancel, setIsOpenCancel] = useState({
    value: false,
    content: ""
  })
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)
  const data = JSON.parse(atob(query?.result as string) || "")
  // const data = {barcode:'131255961', status:'success',server_id:'3', payment:'momo'}

  const { getValueByKey } = useContentGlobal();

  const { data: dataOrder, isLoading } = useQuery(["order", {
    data: data
  }], async () => {

    const res = await fetch(`/api/order/?barcode=${data?.barcode}&id_Server=${data?.server_id}`).then(res => {
      return res.json()
    }).catch((err) => {
      sendLog({
        action: "fetch_data_order",
        page_name: "CheckoutStep3",
        page_url: router.asPath,
        session_key: session_key,
        status: "fail",
        error_message: JSON.stringify(err),
        information: "Fetch data order fail",
        request_json: JSON.stringify({
          data: data
        })
      })
    })
    const time_label = convertDate(res?.data?.[0]?.showtimes_date)
    return {
      ...res?.data[0],
      time_label_vn: time_label?.label_vn,
      time_label_en: time_label?.label_en
    }
  }, {
    enabled: !!data
  })

  useEffect(() => {
    //send event purchase to GA4
    if (data?.status == "success" && dataOrder) {
      const tickets = dataOrder?.listseat.map((item: any, index: number) => {
        return {
          item_id: item?.sid,
          item_name: item?.sname,
          item_category: item?.stickettype,
          item_type: "ticket",
          index: index,
          theater_id: dataOrder?.theater_id,
          theater_name_vn: dataOrder?.theater_name_vn,
          theater_name_en: dataOrder?.theater_name_en,
          room_type_id: dataOrder?.room_type_id,
          room_type_name_vn: dataOrder?.room_type_name_vn,
          room_type_name_en: dataOrder?.room_type_name_en,
          movie_name_vn: dataOrder?.movie_name_vn,
          movie_name_en: dataOrder?.movie_name_en,
          price: item?.sprice,
          quantity: 1,
        }
      })
      const comboPicks = dataOrder?.listcombo.map((item: any, index: number) => {
        return {
          item_id: item?.cid,
          item_name: item?.cname,
          index: tickets.length + index,
          price: item?.cprice,
          quantity: item?.cquantity,
          item_type: "combo",
        }
      })
      gtag("event", "purchase", {
        transaction_id: dataOrder?.barcode,
        value: dataOrder?.amount,
        currency: "VND",
        total_seats: dataOrder?.total_seats,
        seats_name: dataOrder?.seats_name,
        combo_name: dataOrder?.combo_name,
        items: [
          ...(tickets ?? []),
          ...(comboPicks ?? [])
        ]
      })
    }
  }, [data?.status, dataOrder])


  const renderTypePayment = () => {
    if (data?.payment == "onepay_domestic") {
      return getValueByKey(["Checkout", "Domestic_Card"]) ?? "thẻ nội địa"
    }
    if (data?.payment == "onepay_international") {
      return getValueByKey(["Checkout", "International_Card"]) ?? "thẻ quốc tế"
    }
    if (data?.payment == "momo") {
      return "momo"
    }
    return ""
  }

  const saveHtmlAsImage = async () => {
    try {
      setIsLoadingDownload(true)
      var data = document.getElementById('ticketToPrint')

      if (data) {
        await toPng(data).then((dataUrl: any) => {
          var elem = window.document.createElement('a');
          elem.href = dataUrl
          elem.download = toggleLanguage(dataOrder, "movie_name") || 'ticket.png';
          (document.body || document.documentElement).appendChild(elem);
          if (typeof elem.click === 'function') {
            elem.click();
          } else {
            elem.target = '_blank';
            elem.dispatchEvent(new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true
            }));
          }
          URL.revokeObjectURL(elem.href);
          elem.remove()
          setIsLoadingDownload(false)
        })
      }
    } finally {
      setIsLoadingDownload(false)
    }


  }


  const resentEmail = useMutation(async () => {
    await booking.resendBookingInfo({
      barcode: data?.barcode,
      id_server: data?.server_id
    }).then(() => {
      setIsOpenCancel({ value: true, content: getValueByKey(["Message", "Success", "Send_Mail"]) })
    })
  })

  const handleCancel = () => {
    // window.history.back()
    setIsOpenCancel({ value: false, content: isOpenCancel.content })
  }

  if (isLoading) return <LoadScreen />

  return (<>
    <PopupNoti isOpen={isOpenCancel.value} handleClose={handleCancel} content={isOpenCancel.content} />
    <section className="checkout checkout-success ht">
      <div className="container">
        {data?.status == "failed" ?
          <div className="checkout-success-wr">
            <h2 className="heading">{getValueByKey(["Checkout", "Failed_Payment"])}</h2>
            <div className='grid justify-center my-[4rem]'>
              <p className='flex justify-center'>{getValueByKey(["Checkout", "Failed_Payment_Mes"])}</p>
              <p className='flex justify-center'>{getValueByKey(["Checkout", "Thanks"])}</p>
            </div>
          </div>
          :
          <div className="checkout-success-wr">
            <div className="checkout-success sec-heading" data-aos="fade-up">
              <h2 className="heading">{getValueByKey(["Checkout", "Success_Payment"])} {renderTypePayment()}</h2>
              <ul className="process">
                <li className="process-item process-cus active"> <a className="link" href=""><span className="num">1 </span><span className="txt">
                  {getValueByKey("Customer_Info")}</span></a></li>
                <li className="process-item process-cus active"><a className="link" href="">
                  <span className="num">2</span><span className="txt">{getValueByKey(["Checkout", "Payment"])}</span></a></li>
                <li className="process-item process-cus active"> <a className="link" href="">
                  <span className="num">3</span><span className="txt">
                    {dataOrder?.listseat?.length > 0 ? getValueByKey("Ticket_Information") : getValueByKey("Popcorn_Information")} </span></a>
                </li>
              </ul>
            </div>
            {/* <Button></Button> */}
            {!isLoading &&
              <div className="checkout-success-content">
                <div className="checkout-success-main" ref={ticketRef} id='ticketToPrint' data-aos="fade-up">
                  <div className={`checkout-success-heading`}>
                    {/* {dataOrder?.movie?.image && */}
                    <div className="img-movie">
                      <div className="image">
                        {dataOrder?.movie_id ?
                          <Image quality={100} src={dataOrder?.movie_image} alt="" width={350} height={350} />
                          : <img src='/assets/images/PopcornSoda_Receipt.jpg' alt="" />}
                      </div>
                    </div>
                    {/* } */}

                    <div id="myqrcode" className="img-qrcode">
                      <QRCode value={data?.barcode} className='!w-[100%] !h-[100%] !p-[4rem]' />
                    </div>
                  </div>
                  <div className="form-checkout-cus">
                    <div className="form-main">
                      <div className="inner-info">
                        <div className="inner-info-row">
                          <p className="ct">{toggleLanguage(dataOrder, "movie_name")}</p>
                        </div>
                      </div>
                      <div className="inner-info">
                        <div className="inner-info-row">
                          <p className='tt'>{getValueByKey(toggleLanguage(dataOrder, "limitage")?.toUpperCase()) ?? ""}</p>
                        </div>
                      </div>
                      <div className="inner-info">
                        <div className="inner-info-row">
                          <p className="ct">{toggleLanguage(dataOrder, "theater_name") || toggleLanguage(dataOrder?.cinemaData, "name")}</p>
                          <p className="dt">{dataOrder?.cinemaInterest?.address || dataOrder?.cinemaData?.address}</p>
                        </div>
                      </div>
                      <div className="inner-info">
                        <div className="inner-info-row code">
                          <p className="tt">{dataOrder?.listseat?.length > 0 ? getValueByKey(["Checkout", "Ticket", "Ticket_Code"]) : getValueByKey(["Checkout", "Ticket", "Ticket_Code_Popcorn"])}</p>
                          <p className="ct">{data?.barcode}</p>
                        </div>
                        {dataOrder?.showtimes_time && <div className="inner-info-row time-line">
                          <p className="tt">{getValueByKey(["Checkout", "Ticket", "Showtime"])}</p>
                          <p className="ct">
                            <span className="time">{dataOrder?.showtimes_time}</span>
                            <span className="date"> {toggleLanguage(dataOrder, "time_label")}, {dataOrder?.showtimes_date}</span>
                          </p>
                        </div>
                        }
                      </div>
                      <div className="inner-info">
                        {dataOrder?.room_name &&
                          <div className="inner-info-row room">
                            <p className="tt">{getValueByKey(["Checkout", "Ticket", "Room"])}</p>
                            <p className="ct">{dataOrder?.room_name}</p>
                          </div>
                        }
                        {/* {dataPayment?.qtyTicket && dataPayment?.qtyTicket?.map((item: any) => { */}
                        {/* return <Fragment key={item?.ticket_id}> */}
                        {dataOrder?.listseat?.length > 0 ? (<div className="inner-info-row num-ticket">
                          <p className="tt">{getValueByKey(["Checkout", "Ticket", "Seat_Quantity"])}</p>
                          <p className="ct">{dataOrder?.total_seats}</p>
                        </div>) : <></>}

                        {/* <div className="inner-info-row type-ticket">
                              <p className="tt">Loại vé</p>
                              <p className="ct">{dataOrder?.ticket_name}</p>
                            </div> */}
                        {/* </Fragment> */}
                        {/* })} */}
                      </div>
                      {dataOrder?.listseat?.length > 0 &&
                        <div className="inner-info">
                          <div className="inner-info-row type-position">
                            <p className="tt">{getValueByKey(["Checkout", "Ticket", "Seat_Type"])}</p>
                            <p className="ct">{toggleLanguage(dataOrder, "room_type_name")}</p>
                          </div>
                          <div className="inner-info-row num-position">
                            <p className="tt">{getValueByKey(["Checkout", "Ticket", "Seat_Number"])}</p>
                            <p className="ct">{dataOrder?.listseat?.map((val: any) => val?.sname)?.join(",")}</p>
                          </div>
                        </div>
                      }
                      {dataOrder?.listcombo?.length > 0 &&
                        <div className="inner-info">
                          <div className="inner-info-row corn-drink">
                            <p className="tt">{getValueByKey(["Checkout", "Ticket", "Concessions"])}</p>
                            {dataOrder?.listcombo?.map((item: any) => {
                              return <Fragment key={item?.id}>
                                <p className="ct">{item?.cquantity} {item?.cname}</p>
                              </Fragment>
                            })}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="btn-gr">
                  <button onClick={saveHtmlAsImage} className="btn btn--pri h-[41px]"> {isLoadingDownload ? <i className="fa-regular fa-circle-notch fa-spin"></i> : getValueByKey(["Checkout", "Ticket", "Download_Ticket"])} </button>
                  <div className="btn btn--white !h-[41px]" onClick={() => resentEmail.mutate()}>
                    {resentEmail.isLoading ?
                      <i className="fas fa-spinner fa-spin"></i>
                      :
                      getValueByKey(["Checkout", "Ticket", "Become_A_Member"])
                    }
                  </div>
                </div>
              </div>}
          </div>
        }
      </div>
    </section>
  </>
  )
}