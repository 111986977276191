import clsx from 'clsx'
import { InputHTMLAttributes, useState } from 'react'
import { Control, useController } from 'react-hook-form'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

type TextFieldProps = InputHTMLAttributes<any> & {
  control: Control<any>
  name: string
  label?: string
  className?: string
  classNameLabel?: string
}

const InputRHF = ({
  control,
  type,
  name,
  label,
  className,
  classNameLabel,
  required,
  ...field
}: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name, control })

  return (
    <div className="relative w-full mb-[10px]">
      <div className="form-it">
        <p
          className={`form-label text-[16px] text-[#494949] ${error ? 'text-danger' : ''} ${classNameLabel || ''
            }`}
        >
          {label} {required ? <span className="text-error">*</span> : null}
        </p>
        <div className="relative">
          <input
            type={type === 'password' ? (showPassword ? 'text' : 'password') : 'text'}
            {...field}
            ref={ref}
            onBlur={onBlur}
            // required={required}
            className="form-control input"
            value={value || ''}
            onChange={onChange}
          />


          {type === 'password' ? (
            <div
              className="absolute left-[95%] top-[35%] -translate-x-2/4 -translate-y-[5%] cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          ) : null}
        </div>
      </div>
      {!!error ? (
        <div className="absolute mt-1">
          <div className={clsx(error ? 'flex items-center gap-2 overflow-visible' : 'hidden')}>
            <ErrorMessage>{error?.message || 'Vui lòng nhập trường này'}</ErrorMessage>
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default InputRHF



const ErrorMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <p className="text-error text-[12px] mt-[2px]">{children}</p>
  )
}