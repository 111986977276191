import { correctPassword, isEmail, isValidPhoneNumber } from "@/helper/functions";
import useContentGlobal from "@/hooks/useContentGlobal";
import { FC, useEffect, useRef, useState } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  initValue?: string;
  className?: string;
  messageError?: string;
  classNameWrap?: string;
  checkValue?: string;
  isCheckValuePass?: boolean;
  onChange?: any;
}

export const Input: FC<InputProps> = ({
  className,
  checkValue,
  classNameWrap,
  initValue = "",
  messageError = "",
  isCheckValuePass = false,
  onChange,
  ...props
}) => {
  const [mesError, setMesError] = useState<string>(messageError);
  const inputRef = useRef<HTMLInputElement>(null);

  const { getValueByKey } = useContentGlobal();

  useEffect(() => {
    if (inputRef.current !== null && initValue)
      inputRef.current.value = initValue;
  }, [inputRef, initValue]);

  useEffect(() => {
    setMesError(messageError)
  }, [messageError]);

  const handleClearMesError = () => {
    if (mesError)
      setMesError("")
  }

  const handleOnChange = (e: any) => {
    onChange?.(e);

    const _value = e.target.value;
    if (props.type === "password" && isCheckValuePass && !correctPassword(_value)) 
      return setMesError(getValueByKey(["Message", "Error", "Password"]));

    if (
      _value === ""
      || props.type === "email" && !isEmail(_value)
      || props.type === "password" && checkValue && checkValue !== _value
      || props.type === "tel" && !isValidPhoneNumber(_value)
    )
      return setMesError(messageError);

    return handleClearMesError();
  }

  const renderMessageError = () => {
    if (!mesError) return null;
    return (<p className="absolute text-error text-[12px]">{mesError}</p>);
  };

  return (
    <div className={`w-full relative mb-input ${classNameWrap}`}>
      <input
        ref={inputRef}
        className={`${className} ${mesError ? "input-error" : ""}`}
        onChange={handleOnChange}
        {...props}
      />
      {renderMessageError()}
    </div>
  );
};

export default Input;