/* eslint-disable @next/next/no-img-element */
import React, { useRef, useState } from 'react'
import router from 'next/router'

export const SearchHome = () => {
  const [content, setContent] = useState("")

  const inputSearchRef = useRef<any>(null);
  
  const handleSearch = () => {
    inputSearchRef.current?.blur();
    router.push(`/search/?keyword=${content}`);
  }

  return (
    <section className="sec-re-search">
      <div className="re-search sc-pd">
        <div className="re-search-br" data-aos="fade-up">
          <img src="./assets/images/re-search-br.jpg" alt="" />
        </div>
        <div className="container">
          <div className="sec-heading" data-aos="fade-up">
            <h2 className="heading">TÌM KIẾM TẤT CẢ MỌI THỨ</h2>
          </div>
          <div className="re-search-form" data-aos="fade-up">
            <div>
              <div className="re-search-form-wr">
                <input value={content} className="re-input" type="text" name="" placeholder="Tìm phim , rạp và thành phố " onChange={(e) => setContent(e.target.value)} ref={inputSearchRef} />
                <button className="re-search-form-btn btn btn--pri btn-pad-1" type="submit" onClick={handleSearch}>
                  <img src="./assets/images/re-search-icon.svg" alt="" />
                  <span className="txt">Tìm Kiếm Ngay</span>
                </button>
              </div>
            </div>
          </div>
          <div className="re-search-tg" data-aos="fade-up">
            <span className="tt">Tìm kiếm gần đây :</span>
            <div className="re-search-tg-btn" onClick={() => setContent("bắp")}>
              <span className="txt"> bắp</span>
            </div>
            <div className="re-search-tg-btn" onClick={() => setContent("Phim mới")}>
              <span className="txt"> Phim mới</span>
            </div>
            <div className="re-search-tg-btn" onClick={() => setContent("Rạp hai bà trưng")}>
              <span className="txt"> Rạp hai bà trưng</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}