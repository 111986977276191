import useContentGlobal from '@/hooks/useContentGlobal'
import { coupon } from '@/services'
import Link from 'next/link'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'


type Props = {
  isOpen: boolean,
  handleCanle: any,
  handleNext: (value: string) => void,
  handleApplyComboCode: (value: string) => void
  value: string
  setValue: (value: string) => void
}

export const CheckoutSalePopup = (props: Props) => {
  const { control, setValue, watch } = useForm();
  const { getValueByKey } = useContentGlobal();

  const { data: listPromotion } = useQuery({
    queryKey: ["listPromotion"],
    queryFn: async () => {
      return await coupon.getList()
    },
    select: (data: any) => data.promotion

  })
  const promotion = listPromotion?.filter((item: any) => item.type === "promotion")?.sort((a: any, b: any) => a.sort_order - b.sort_order)
  const couponCode = listPromotion?.filter((item: any) => item.type === "coupon_code")?.sort((a: any, b: any) => a.sort_order - b.sort_order)
  const handleSubmitForm = async (e: any) => {
    e.preventDefault()
    // props?.handleNext('ádasdasdasdasdas')
    // console.log(e.target.inputDiscount.value, e.target.radioCoupon.value)
    if (e.target.inputDiscount.value !== "") {
      props?.setValue(e.target.inputDiscount.value)
      props?.handleApplyComboCode(e.target.inputDiscount.value)
      props?.handleNext(e.target.inputDiscount.value)
    } else if (e.target.radioCoupon.value !== "") {
      props?.setValue(e.target.radioCoupon.value)
      props?.handleApplyComboCode(e.target.radioCoupon.value)
      props?.handleNext(e.target.radioCoupon.value)
    }
  }
  console.log(watch('radioCoupon'), watch('inputDiscount'))

  return (
    <div className={props.isOpen ? "popup popup-gg open" : "popup popup-gg"}>
      <div className="popup-overlay" onClick={() => props.handleCanle()}>
      </div>
      <div className="popup-main">
        <div className="popup-main-wrapper">
          <div className="popup-over">
            <div className="popup-wrapper">
              <div className="popup-gg-wr">
                <div className="form-discount">
                  <form onSubmit={(e) => handleSubmitForm(e)}>
                    <div className="form-list">
                      <div className="subtitle">{getValueByKey(["Checkout", "Discount", "Apply_Discounts"])}</div>
                      <div className="form-it popup-gg-search">
                        <div className="input">
                          <Controller control={control} name='inputDiscount' render={({ field }) =>
                            <input
                              {...field}
                              onPointerEnterCapture={() => { }}
                              className="form-control re-input"
                              type="text"
                              placeholder={getValueByKey(["Checkout", "Discount", "Placeholder"])}
                              id="contact-main-fName"
                              name="inputDiscount"
                              onChange={(e) => {
                                field.onChange(e)
                                setValue('radioCoupon', '')
                              }}
                            />} />

                        </div>
                      </div>
                      <div className="form-it list-discount">
                        {couponCode &&
                          couponCode.map((item: any) => (
                            <div className="inner-radio" key={item.id}>
                              <Controller control={control} name='radioCoupon' render={({ field }) =>
                                <input
                                  {...field}
                                  onPointerEnterCapture={() => { }}
                                  className="form-control"
                                  type="radio"
                                  id={item.id}
                                  name="radioCoupon"
                                  value={item?.code}
                                  hidden
                                  checked={item?.code === watch('radioCoupon')}
                                  onChange={(e) => {
                                    field.onChange(e)
                                    setValue('inputDiscount', '')
                                  }}
                                />} />
                              <label className="form-label" htmlFor={item.id}>
                                <div className="content">
                                  <p className="tt">{item?.name} </p>
                                  <p className="note">{item?.short_description || item?.description} </p>
                                </div>
                                <div className="popup-gg-checkbox"></div>
                              </label>
                            </div>
                          ))}
                        {promotion &&
                          promotion.map((item: any) => (
                            <div className="inner-radio not-action" key={item?.id}>
                              <label className="form-label">
                                <div className="content">
                                  <p className="tt">{item?.name}</p>
                                  <p className="note">{item?.short_description || item?.description}</p>
                                </div>
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="form-it" >
                      <button className="btn btn-submit btn--pri --m-top">{getValueByKey("Continue")}</button>
                    </div>
                  </form>
                  <Link href='/chuong-trinh-khuyen-mai/' className='text-[#0F172A] w-full flex justify-center mt-[4px] underline'> Chú ý mọi chi tiết khuyến mãi</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-close" onClick={() => props.handleCanle()}>
          <i className="fas fa-times icon"></i>
        </div>
      </div>
    </div>
  )
}