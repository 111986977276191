/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'
import { CheckoutSalePopup } from './CheckoutSalePopup'
import { PopupNoti } from '../common'
import useContentGlobal from '@/hooks/useContentGlobal'

type Props = {
   handleChangeCheckout: any,
  setTypePayment: (value: string) => void,
  loading: boolean
  handleApplyComboCode: (code: string) => void
  handleAddCombo: (val:string) => void
  typePayment: string
  handleBack: () => void
}
export const CheckoutStep2 = (props: Props) => {
  const [isOpenLogout, setIsOpenLogout] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState("")

  const { getValueByKey } = useContentGlobal();

  const handleLogout =  (e:any) => {
     e.preventDefault();
     setIsOpenLogout(!isOpenLogout)
  }
  const handleCancleLogout = () => {
     setIsOpenLogout(false)
  }
  const handleNext = (val: string) => {
    props?.handleAddCombo(val)
    handleCancleLogout()
    setValue("")
  }

   return (
      <>
        <div className="form-payment">
          <PopupNoti isOpen={isOpen} handleClose={() => setIsOpen(false)} content={getValueByKey(["Message", "Warning", "Promo_Code_Empty"])} />

          <form className="form" action="">
            <div className="form-list">
              <div className="form-it inner-radio inner-radio-white" onClick={() => props?.setTypePayment("momo")}>
                    <input className="form-control" type="radio" id="payment1" name="input-checkout-payment" hidden />
                    <label className="form-label" htmlFor="payment1"> <span className="img"><img src="/assets/images/img-momo.png" alt="" /></span>
                      <p className="text">{getValueByKey(["Checkout","Payment_Method","Momo"])}</p>
                    </label>
              </div>
              <div className="form-it inner-radio inner-radio-white" onClick={() => props?.setTypePayment("domestic")}>
                    <input className="form-control" type="radio" id="payment2" name="input-checkout-payment" hidden />
                    <label className="form-label" htmlFor="payment2"> <span className="img"><img src="/assets/images/img-card.png" alt="" /></span>
                      <p className="text">{getValueByKey(["Checkout","Payment_Method","Card"])}</p>
                    </label>
              </div>
              <div className="form-it inner-radio inner-radio-white" onClick={() => props?.setTypePayment("international")}>
                <input className="form-control" type="radio" id="payment3" name="input-checkout-payment" hidden />
                <label className="form-label" htmlFor="payment3"> <span className="img"><img src="/assets/images/img-card.png" alt="" /></span>
                  <p className="text">{getValueByKey(["Checkout","Payment_Method","Card_International"]) ?? "thẻ quốc tế"}</p>
                </label>
              </div>
            </div>
            <div className="btn-discount" onClick={handleLogout}> <span className="img"><img src="/assets/images/ic-tag-dis.svg" alt="" /></span>
              <div className="text">
                  <p className="tt">{getValueByKey(["Checkout","Discount","Title"])}</p>
                  <p className="note">{getValueByKey(["Checkout","Discount","Subtitle"])}</p>
              </div>
            </div>
            <div className="form-it btn-action">
             <div className={`btn btn-submit btn--pri h-[41px] `} onClick={props.handleBack}>
               {getValueByKey("Back")}
             </div>
             <div className={`btn btn-submit btn--pri h-[41px] ${props?.typePayment === "" ? " opacity-30 pointer-events-none" : ""}`} onClick={props.handleChangeCheckout}>
               {props?.loading ?
                 <i className="fas fa-spinner fa-spin"></i>
                 : getValueByKey(["Checkout", "Submit_Payment"])
               }
             </div>
            </div>
          </form>
        </div>
        
        <CheckoutSalePopup 
          isOpen={isOpenLogout}
          handleCanle={handleCancleLogout}
          handleNext={handleNext}
          handleApplyComboCode={props?.handleApplyComboCode}
          value={value} 
          setValue={setValue}       
        />
      </>
   )
}
