import React from "react";
import { LoadingCustom } from "../screen/common/LoadingCustom";

type TLoadingOverlay = {
  loading: boolean,
  children: React.ReactNode | any
}

const LoadingOverlay: React.FC<TLoadingOverlay> = ({ loading, children }) => {
  return (
    <div className={loading ? "loading-overlay" : ""}>
      <div className={loading ? "loading-content" : ""}>{children}</div>
      <div className={loading ? "loading-indicator" : "hidden"}>
        <div className="w-[50px] h-[50px]">
          <LoadingCustom classCustom="!bg-[inherit]" />
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
