/* eslint-disable @next/next/no-img-element */
import { swiperConfig } from '@/utils/SwiperConfig';
import { useEffect } from 'react'
import parse from 'html-react-parser';
import { useDataUser } from '@/hooks';
import { useQuery } from 'react-query';
import { sendLog } from '@/services/sendLog';
import useSavaSessionKey from '@/hooks/useSavaSessionKey';
import { useRouter } from 'next/router';
import Image from 'next/image';
import useContentGlobal from '@/hooks/useContentGlobal';
import { toggleLanguage } from '@/helper';

export const MemberHome = ({ memberShip }: {
  memberShip: {
    memberShip_en: string,
    memberShip_vn: string
  }
}) => {
  const userInfo = useDataUser();
  const { session_key } = useSavaSessionKey()
  const router = useRouter();
  const { getValueByKey } = useContentGlobal()
  const language = typeof window !== 'undefined' ? localStorage.getItem('language') ?? 'vn' : 'vn'
  useEffect(() => {
    swiperConfig(".memb-slider", {
      speed: 600,
      initialSlide: 0,
      centeredSlides: false,
      loop: false,
      spaceBetween: 0,
      effect: "slide",
      slidesPerView: "auto",
    }, "bullets");
  }, [])

  const { data: levelMember } = useQuery(["LEVEL_MEMBER"], async () => {
    const resAPI: any = await fetch("/api/member/level", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json()).catch((err) => {
      sendLog({
        action: "get_member_level",
        page_name: "MemberHome",
        page_url: router.asPath,
        session_key: session_key,
        status: "fail",
        error_message: JSON.stringify(err),
        information: "Get member level fail",
        request_json: JSON.stringify({})
      })
    })
    return resAPI;
  })

  return (
    <section className="sec-memb">
      <div className="memb sc-pd">
        <div className="memb-br" data-aos="fade-up">
          <picture>
            <source
              srcSet="/assets/images/bg-cfriends-mobile.webp"
              media="(max-width: 767px)"
            />
            <Image src="/assets/images/bg-cfriends.webp" alt="" fill />
          </picture>

        </div>
        {userInfo?.id ? (<div className="container">
          {/* Đã là thành viên */}
          <div className="sec-heading" data-aos="fade-up">
            <h2 className="heading">{getValueByKey(["Account_page", "redirection", "Cinestar_Membership"]) ?? "Thành Viên CỦA CINESTAR"}</h2>
          </div>
          <div className="memb-slider logined" data-aos="fade-up">
            <div className="acc-points">
              <div className="ct"><span className="point"> <span className="poin-current">{userInfo?.accumulate_points}</span><span className="dot">/</span><span className="poin-total">{levelMember?.[1]?.scorelimit}</span></span></div>
              <div className="block-bar">
                <div className="curr-bar" style={{ width: `${userInfo?.accumulate_points || 0 / levelMember?.[1]?.scorelimit * 100}%` }}></div>
              </div>
            </div>
            <div className="memb-wr"> <a className="memb-img" href="" aria-label='Thành Viên CỦA CINESTAR'> <img src={userInfo?.card_type == "C'Friends" ? "./assets/images/img-card-member2.jpg" : "./assets/images/img-card-vip.jpg"} alt="" /></a>
              <div className="memb-content">
                <h3 className="memb-name"> <a href="" aria-label={`Thành Viên ${userInfo?.card_type || "C'Friends"}`}>{language === 'vn' ? `Thành Viên ${userInfo?.card_type || "C'Friends"}` : `${userInfo?.card_type || "C'Friends"} Member`}</a></h3>
              </div>
            </div>
          </div>
        </div>) : parse(toggleLanguage(memberShip, "memberShip") || '')}
      </div>
    </section>
  )
}