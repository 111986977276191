/* eslint-disable @next/next/no-img-element */
import $ from "jquery"
import React, { useEffect } from 'react'


export const SystemCinema = () => {
  useEffect(() => {
    const collapseBlockJS = document.querySelectorAll('.collapseBlockJS');
    if (collapseBlockJS) {
      collapseBlockJS.forEach(item => {
        const clpItems: any = item.querySelectorAll('.collapseItem')
        const clpBodys: any = item.querySelectorAll('.collapseBody')
        $(clpItems[0]).addClass('active');
        $(clpBodys[0]).slideDown();
      })
    }
  }, [])

  const handleCollapse = (e: any) => {
    const head = e.target.closest('.collapseHead');
    const item = e.target.closest('.collapseItem');

    if (head) {
      const body = head.closest('.collapseItem').querySelector('.collapseBody');
      const clBodys = head.closest('.collapseBlockJS').querySelectorAll('.collapseBody');
      const clItems = head.closest('.collapseBlockJS').querySelectorAll('.collapseItem');
      clItems.forEach((item: any) => {
        $(item).removeClass("active");
      })
      clBodys.forEach((bd: any) => {
        $(bd).slideUp();
      })
      $(body).slideToggle()
      $(item).toggleClass("active")
    }

  }
  return (
    <section className="ab-branch contact-branch">
      <div className="container">
        <div className="contact-branch-wr">
          <div className="contact-branch-heading sec-heading" data-aos="fade-up">
            <div className="heading">
              <h1>HỆ THỐNG CÁC CỤM RẠP</h1>
            </div>
            <div className="desc">
              <p className="txt">Các phòng chiếu được trang bị các thiết bị tiên tiến như hệ thống âm thanh vòm, màn hình rộng và độ phân giải cao, tạo nên hình ảnh sắc nét và âm thanh sống động.</p>
            </div>
          </div>
          <div className="contact-branch-body row">
            <div className="contact-branch-left col col-6" data-aos="fade-up">
              <div className="image"> <img src="/assets/images/img-vietnam_map.png" alt="" />
              </div>
            </div>
            <div className="contact-branch-right col col-6">
              <div className="branch-list address-list collapseBlockJS" onClick={(e) => handleCollapse(e)}>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">TRỤ SỞ | HEADQUATER </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">135 Hai Bà Trưng, Phường Bến Nghé, Quận 1</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-mail.svg" alt="" /></span><span className="txt">marketingcinestar@gmail.com</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt"> 028 7300 7279</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR HAI BÀ TRƯNG </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt">5 phòng chiếu với 725 ghế.</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">135 Hai Bà Trưng, Phường Bến Nghé, Quận 1</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR QUỐC THANH </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt">6 phòng chiếu với 930 ghế.</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">271 Nguyễn Trãi, Phường Nguyễn Cư Trinh, Quận 1</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR STUDENT (BÌNH DƯƠNG) </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt">6 phòng chiếu với 1592 ghế</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">Nhà văn hóa Sinh Viên, ĐHQG Thành phố Hồ Chí Minh</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR HUẾ </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt">8 phòng chiếu với 1200 ghế</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">25 Hai Bà Trưng, Phường Vĩnh Ninh, TP.Huế</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR MỸ THO </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt">5 phòng chiếu với 912 ghế</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">52 Đinh Bộ Lĩnh, Phường 3, TP. Mỹ Tho</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR KIÊN GIANG </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt">4 phòng chiếu với 750 ghế</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">TTTM Rạch Sỏi, Phường Rạch Sỏi, TP. Rạch Giá</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR LÂM ĐỒNG </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt">4 phòng chiếu với 853 ghế</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">713 QL20, Liên NghĩaĐưc Trọng, Lâm Đồng</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="branch-item address-item collapseItem" data-aos="fade-up">
                  <div className="address-box">
                    <div className="branch-heading address-box-head collapseHead">
                      <h4 className="sub-tittle">CINESTAR ĐÀ LẠT </h4><i className="fa-solid fa-angle-down icon"></i>
                    </div>
                    <div className="branch-item-body collapseBody">
                      <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                    </div>
                    <div className="career-item-footer">
                      <ul className="list">
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-room.svg" alt="" /></span><span className="txt"> 6 phòng chiếu với 1592 ghế</span></a></li>
                        <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">Quảng trường Lâm Viên, TP. Đà lạt</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}