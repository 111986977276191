/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';

import useDataUser from '@/hooks/useDataUser';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { FormatNumber } from '@/helper/functions';
import { sendLog } from '@/services/sendLog';
import { clearUserInfo } from '@/store/userSlice';
import { TUserLevel } from '@/types/user';
import { swiperConfig } from '@/utils/SwiperConfig';
import { useDispatch } from 'react-redux';
import AvatarChangeButton from './AvatarChangeButton';
import { PopupConfirmLogout } from './PopupConfirmLogout';
import { clearUserTempInfo } from '@/store/userTemp';
import { useQuery } from 'react-query';
import { innitMemberLevel } from '@/components/pages/account/account-member';
import useContentGlobal from '@/hooks/useContentGlobal';

export const URL_DEFAULT_AVATAR = "https://cinestar.monamedia.net/assets/images/ic-header-auth.svg"

export const AccountSideBar = () => {
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const [memberLevel, setMemberLevel] = useState<Record<string, TUserLevel>>(innitMemberLevel);

  const router = useRouter();
  const dispatch = useDispatch();
  const userInfo = useDataUser();
  
  const { getValueByKey } = useContentGlobal();

  const totalPoints = parseInt(userInfo?.total_points ?? "0");

  const MAIN_FEATURES_LEFT_SIDE = [
    {
      title: getValueByKey(["Account_page","redirection","Account_Info"]) ?? "Thông tin khách hàng",
      url: "/account/account-profile",
      img: "/assets/images/ic-user-circle.svg"
    },
    {
      title: getValueByKey(["Account_page","redirection","Cinestar_Membership"]) ?? "Thành viên Cinestar",
      url: "/account/account-member",
      img: "/assets/images/acc-menu-2.svg"
    },
    {
      title: getValueByKey(["Account_page","redirection","Purchase_History"]) ?? "Lịch sử mua hàng",
      url: "/account/account-history",
      img: "/assets/images/acc-menu-3.svg"
    }
  ];

  const handleSendLog = (error: any) => {
    sendLog({
      action: "get_member_level",
      page_name: "AccountSideBar",
      page_url: router.asPath,
      session_key: "",
      status: "fail",
      error_message: JSON.stringify(error),
      information: "Get member level fail",
      request_json: JSON.stringify({})
    })
  }

  const handleGetMemberLevel = async () => {
    const resAPI: any = await fetch("/api/member/level", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json()).catch((err) => handleSendLog(err))

    setMemberLevel(resAPI);
  }

  useQuery({
    queryKey: ['LEVEL_MEMBER'],
    queryFn: handleGetMemberLevel,
    onError: (err: any) => {
      handleSendLog(err)
    },
  });

  useEffect(() => {
    if (window.innerWidth < 800) {
      swiperConfig(".acc-menu-slider", {
        speed: 600,
        initialSlide: 0,
        centeredSlides: false,
        loop: false,
        spaceBetween: 0,
        effect: "slide",
        slidesPerView: "auto",
      }, "bullets");
    }
  }, [])

  const formatNumberWithK = (numString: string | undefined): string => {
    if (numString === undefined) return "";

    const num = parseFloat(numString);

    if (isNaN(num)) {
      return "Invalid number";
    }

    if (num >= 1000) {
      const numInK = num / 1000;
      const formattedNum = numInK % 1 === 0 ? numInK.toFixed(0) : numInK.toFixed(1);
      return formattedNum + "K";
    } else {
      return num.toString();
    }
  }

  const handleOpenModalLogout = (e: any) => {
    e.preventDefault();
    setIsOpenLogout(!isOpenLogout)
  }
  const handleCancelLogout = () => {
    setIsOpenLogout(false)
  }

  const handleLogout = () => {
    dispatch(clearUserInfo());
    dispatch(clearUserTempInfo());
    return router.push("/");
  }

  const renderListFeatures = () => {
    const { pathname } = router;
    return MAIN_FEATURES_LEFT_SIDE.map((feature, index) => {
      return (
        <div
          key={index}
          className={classNames(
            "swiper-slide col",
            { active: pathname === feature.url }
          )}>
          <Link href={feature.url} className="acc-menu-link">
            <>
              <img src={feature.img} alt="" />
              <span className="txt">{feature.title}</span>
            </>
          </Link>
        </div>
      );
    });
  }

  return (
    <>
      <div className="acc-sbar">
        <div className="acc-sbar-block">
          <div className="about-user">
            <div className="ava">
              <img className='rounded-[100%]' src={userInfo.image ? userInfo.image : URL_DEFAULT_AVATAR} alt="" />
            </div>
            <div className="info">
              <p className="name">
                {userInfo.name}
              </p>
              <AvatarChangeButton />
              {/* <p className="about-user-action">Thay đổi ảnh đại diện</p> */}
            </div>
          </div>
          <div className="about-user-lv">
            {userInfo.card_type}
          </div>
          <div className="acc-points">
            <div className="ct">
              <span className="tt">{getValueByKey("Accumulate_Points")}
                <span style={{ color: "#F3EA28" }}> {userInfo.card_type}</span>
              </span>
            </div>
            <div className="block-bar">
              <div className="curr-bar" style={{
                width: `${totalPoints * 100 / parseInt(memberLevel?.[1]?.scorelimit ?? "0")}%`
              }}></div>
            </div>
            <span className="point">
              <span className="poin-current">{FormatNumber(parseInt(totalPoints + ""), '.')}</span>
              <span className="dot">/</span>
              <span className="poin-total">{formatNumberWithK(memberLevel?.[1]?.scorelimit)}</span>
            </span>
          </div>
        </div>
        <div className="acc-sbar-block">
          <div className="acc-menu">
            <div className="acc-menu-slider">
              <div className="swiper-container">
                <div className="swiper rows">
                  <div className="swiper-wrapper">
                    {renderListFeatures()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="acc-sbar-block">
          <div className="act-logout">
            <a className="logout" href="" onClick={handleOpenModalLogout}>
              <span className="ic">
                <img src="/assets/images/ic-logout.svg" alt="" />
              </span>
              <span className="txt">{getValueByKey("Log_Out") ?? "Đăng xuất"}</span>
            </a>
          </div>
        </div>
      </div>
      <PopupConfirmLogout
        isOpen={isOpenLogout}
        handleCanle={handleCancelLogout}
        handleLogout={handleLogout}
      />
    </>
  )
}