/* eslint-disable @next/next/no-img-element */
import { useRef, useState } from 'react';
import Input from '@/components/inputs/input';
import LoadingOverlay from '@/components/loading';
import { isEmail } from '@/helper/functions';
import contactApi from '@/services/contact';
import { PopupNoti } from '@/components/screen/common/PopupNoti';
import useContentGlobal from '@/hooks/useContentGlobal';
import useStatePopupNotify from '@/hooks/useStatePopupNotify';
import parser from 'html-react-parser';

const initErrorMessage = {
  name: "",
  email: "",
  content: "",
}
type TProps = {
  footerContactCms: {
    left: string;
    top: string;
  }
}

export const ContactHome = ({ footerContactCms }: TProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorField, setErrorField] = useState(initErrorMessage);
  const { popupNotify, handleOpenNotify, handleClosedNotify } = useStatePopupNotify();

  const { getValueByKey } = useContentGlobal();

  const formRef = useRef<HTMLFormElement | any>(null);

  const handleSetErrorMessage = (message: string) => (
    listError: any,
    field: string,
    value: string | boolean
  ) => {
    if (!value) return { ...listError, [field]: message };
    return { ...listError, [field]: "" }
  }

  const checkEmptyField = handleSetErrorMessage("Vui lòng nhập thông tin");
  const checkErrorEmail = handleSetErrorMessage("Email chưa chính xác");

  const handleGetValueForm = (indexValue: number[]): string[] => {
    return indexValue.map((currentValue: number) => {
      return formRef.current?.[currentValue]?.value ?? ""
    }, [])
  }

  const handleCheckValueForm = () => {
    if (formRef.current === null) return;

    const [name = "", email = "", content = ""] = handleGetValueForm([0, 1, 2]);

    let _errorField = errorField;
    _errorField = checkEmptyField(_errorField, "name", name);
    _errorField = checkEmptyField(_errorField, "email", email);
    _errorField = checkEmptyField(_errorField, "content", content);

    if (email && !isEmail(email)) {
      _errorField = checkErrorEmail(_errorField, "email", isEmail(email))
    }

    setErrorField(_errorField);
  }

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    setIsLoading(true)
    const [name = "", email = "", content = ""] = handleGetValueForm([0, 1, 2]);

    if (!isEmail(email)) {
      return setIsLoading(false);
    }

    try {
      const res: any = await contactApi.contactInfo({
        input: {
          name,
          email,
          content,
        }
      });

      setIsLoading(false);

      if (res?.sendContactInfo?.status === 200 || res) {
        e?.target?.reset();
        handleOpenNotify('Gửi thành công');
        return;
      }

      handleOpenNotify(getValueByKey(["Message", "Error", "Had_Error"]) ?? 'Có lỗi xảy ra');
    } catch (error) {
      setIsLoading(false);
      handleOpenNotify(getValueByKey(["Message", "Error", "Had_Error"]) ?? 'Có lỗi xảy ra');
      return;
    }
  }

  return (
    <section className="sec-ct">
      <PopupNoti isOpen={popupNotify.isOpen} handleClose={handleClosedNotify} contentTitle={popupNotify.message} />
      <div className="ct sc-pd">
        <div className="container">
          <div className="ct-side row">
            <div className="ct-left col col-6" data-aos="fade-up">

              {parser((parser(footerContactCms.left,) as any)?.props?.children)}
            </div>
            <div className="ct-right col col-6" data-aos="fade-up">

              <LoadingOverlay loading={isLoading}>
                <div className="ct-box">
                  {parser((parser(footerContactCms.top,) as any)?.props?.children)}
                  <div className="ct-form">
                    <form ref={formRef} onSubmit={handleSubmitForm}>
                      <div className="f-list row">
                        <div className="f-item col">
                          <label className="re-label"></label>
                          <Input className="re-input" type="text" name="" required placeholder={getValueByKey("Full_Name") ?? "Họ và tên"} messageError={errorField.name} />
                        </div>
                        <div className="f-item col">
                          <label className="re-label"></label>
                          <Input className="re-input" type="email" name="" required placeholder={getValueByKey("Email_Placeholder") ?? "Email của bạn"} messageError={errorField.email} />
                        </div>
                        <div className="f-item col">
                          <label className="re-label"></label>
                          <textarea
                            className="re-input"
                            name=""
                            cols={30}
                            rows={10}
                            required
                            placeholder={getValueByKey("Contact_Content") ?? "Thông tin liên hệ hoặc phản ánh"}
                            style={errorField.content ? {
                              border: "1.5px solid red"
                            } : {}}
                          ></textarea>
                        </div>
                      </div>
                      <button className="btn btn--pri btn-pad-2" type="submit" onClick={handleCheckValueForm}>
                        <span className="txt">{getValueByKey("Send") ?? "GỬI NGAY"} </span>
                      </button>
                    </form>
                  </div>
                </div>
              </LoadingOverlay>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}