/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
import { _format } from '@/utils/format';
import { useEffect, useState } from 'react';

export const PopcornItem = ({ data, comboPick, setComboPick } : any) => {
  const [quantity, setQuantity] = useState(0);


  const handlePlus = () => {
    setQuantity(quantity + 1);
  }
  const handleMinus = () => {
    if (quantity <= 0) {
      return;
    } else {
      setQuantity(quantity - 1);
    }
  }

  useEffect(() => {
    if (quantity > 0) {
      const indexIsExist = comboPick?.findIndex((item: any) => item?.id == data?.id)
      if (indexIsExist >= 0) {
        const newArr = comboPick?.filter((item: any) => item?.id !== data?.id)
        setComboPick([...newArr, { ...data, qty: quantity }])
      } else {
        setComboPick([...comboPick, { ...data, qty: quantity }])
      }
    } else {
      const newArr = comboPick?.filter((item: any) => item?.id !== data?.id)
      setComboPick([...newArr])
    }
  }, [quantity])


  return (
    <div className="combo-item col col-4" data-aos="fade-up">
      <div className="food-box">
        <div className="img"><a className="image"> <img src={`${data?.image}`} alt="" /></a></div>
        <div className="content">
          <div className="content-top"><a className="name sub-title">{data?.name}</a>
            <div className="desc">
              <p>{data?.description}</p>
            </div>
            <div className="price sub-title">
              <p>{_format.getVND(parseFloat(data?.price), "VND")}</p>
            </div>
          </div>
          <div className="content-bottom">
            <div className="count">
              <div className="count-btn count-minus" onClick={handleMinus}><i className="fas fa-minus icon"></i></div>
              <p className="count-number">{quantity}</p>
              <div className="count-btn count-plus" onClick={handlePlus}><i className="fas fa-plus icon"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
