import { useEffect } from 'react'
import { CinemaItem } from './CinemaItem'
import {  collapseActionRender } from '@/utils/CollapseConfig'

export const CinemaList = ({ dataCinema }: { dataCinema: TCinima[] }) => {
  useEffect(() => {
    collapseActionRender()
  }, [])
  return (
    <div className="cinema-rental-list row cinema-rental-remake collapseBlockJS">
      {dataCinema?.map((cinima: TCinima) => {
        return <CinemaItem cinima={cinima} key={cinima?.id} />
      })}
    </div>
  )
}
