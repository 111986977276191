/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react'

import { TUserLevel } from '@/types/user'
import useDataUser from '@/hooks/useDataUser'
import { FormatNumber } from '@/helper/functions'
import { AccountSideBar } from '@/components/screen'
import router from 'next/router'
import { sendLog } from '@/services/sendLog'
import useSavaSessionKey from '@/hooks/useSavaSessionKey'
import { useQuery } from 'react-query'
import useContentGlobal from '@/hooks/useContentGlobal'
import Image from 'next/image'

const CARD_TYPE = {
  vip: "C'VIP",
  friend: "C'Friends"
}

export const innitMemberLevel = {
  0: {
    id: "1",
    name: "C'Friends",
    scorelimit: "0",
    active: "0"
  },
  1: {
    id: "2",
    name: "C'VIP",
    scorelimit: "10000",
    active: "0"
  }
}

export const AccountMemberPage = () => {
  const [memberLevel, setMemberLevel] = useState<Record<string, TUserLevel>>(innitMemberLevel);
  const { session_key } = useSavaSessionKey();
  const userInfo = useDataUser();

  const { getValueByKey } = useContentGlobal();

  const isVipMember = userInfo.card_type === CARD_TYPE.vip;
  const totalPoints = parseInt(userInfo?.total_points ?? "0");

  const handleSendLog = (error: any) => {
    sendLog({
      action: "get_member_level",
      page_name: "AccountMemberPage",
      page_url: router.asPath,
      session_key: session_key,
      status: "fail",
      error_message: JSON.stringify(error),
      information: "Get member level fail",
      request_json: JSON.stringify({ account: userInfo?.account || "" })
    })
  }

  const handleGetMemberLevel = async () => {
    await fetch("/api/member/level", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => setMemberLevel(await res?.json())).catch((error) => handleSendLog(error));
  }

  useQuery({
    queryKey: ['LEVEL_MEMBER'],
    queryFn: handleGetMemberLevel,
    onError: (err: any) => {
      handleSendLog(err)
    },
  });

  const formatNumberWithK = (numString: string | undefined): string => {
    if (numString === undefined) return "";

    const num = parseFloat(numString);
    if (isNaN(num)) {
      return "Invalid number";
    }

    if (num >= 1000) {
      const numInK = num / 1000;
      const formattedNum = numInK % 1 === 0 ? numInK.toFixed(0) : numInK.toFixed(1);
      return formattedNum + "K";
    } else {
      return num.toString();
    }
  }

  if (!userInfo || userInfo?.id == "") {
    router.push("/login/?prevUrl=/account/account-member/")
  }

  return (
    <section className="sec-prof">
      <div className="prof ht">
        <div className="container">
          <div className="prof-wr">
            <div className="prof-row row">
              <div className="side-bar col col-3">
                <AccountSideBar />
              </div>
              <div className="prof-main col col-9">
                <div className="sec-heading" data-aos="fade-up">
                  <h2 className="heading">{getValueByKey("Register_Member")}</h2>
                </div>
                <div className="block-memb">
                  <div className="acc-points">
                    <div className="ct">
                      <span className="tt">{getValueByKey("Accumulate_Points")} CVIP MEMBER</span>
                      <span className="point">
                        <span className="poin-current">{FormatNumber(parseInt(totalPoints + ""), ".")}</span>
                        <span className="dot">/</span>
                        <span className="poin-total">
                          {formatNumberWithK(memberLevel?.[1]?.scorelimit)}
                        </span>
                      </span>
                    </div>
                    <div className="block-bar">
                      <div className="curr-bar" style={{ width: `${totalPoints * 100 / parseInt(memberLevel?.[1]?.scorelimit ?? "0")}%` }}></div>
                    </div>
                  </div>
                  <div className="block-memb-wr row">

                    <div className="block-memb-it block-memb-member col">
                      <div className="box-memb">
                        <a className="img-card" href=""><img src="/assets/images/img-card-member2.jpg" alt="" /></a>
                        <div className="memb-hd">
                          <div className="tt">C’FRIEND</div>
                          <div className="desc">{getValueByKey(["Account_page", "Membership", "Friend", "Text_1"])}</div>
                        </div>
                        <div className="memb-bd">
                          <div className="tt">{getValueByKey(["Account_page", "Membership", "Friend", "Text_2"])}: </div>
                          <ul className="list">
                            <li className="item">{getValueByKey(["Account_page", "Membership", "Friend", "Text_3"])}</li>
                            <li className="item">{getValueByKey(["Account_page", "Membership", "Friend", "Text_4"])}</li>
                            <li className="item">{getValueByKey(["Account_page", "Membership", "Friend", "Text_5"])}</li>
                          </ul>
                        </div>
                        <a className="btn">
                          {getValueByKey(["Account_page", "Membership", "Already_Member"])} C’FRIEND
                        </a>
                      </div>
                    </div>

                    <div className="block-memb-it block-memb-vip col">
                      <div className="box-memb">
                        <a className="img-card" href=""><img src="/assets/images/img-card-vip.jpg" alt="" /></a>
                        <div className="memb-hd">
                          <div className="tt">C’VIP</div>
                          <div className="desc">{getValueByKey(["Account_page", "Membership", "VIP", "Text_1"])}</div>
                        </div>
                        <div className="memb-bd">
                          <div className="tt">{getValueByKey(["Account_page", "Membership", "VIP", "Text_2"])}:</div>
                          <ul className="list">
                            <li className="item">{getValueByKey(["Account_page", "Membership", "VIP", "Text_3"])}</li>
                            <li className="item">{getValueByKey(["Account_page", "Membership", "VIP", "Text_4"])}</li>
                          </ul>
                        </div>
                        {/* <div className="memb-ft">
                          <div className="tt">Điều kiện </div>
                          <ul className="list">
                            <li className="item">HSSV xuất trình thẻ HSSV hoặc CCCD từ dưới 22 tuổi.</li>
                            <li className="item">Giảng viên/ giáo viên xuất trình thẻ giảng viên.</li>
                          </ul>
                        </div> */}
                        {isVipMember ? (
                          <a className={isVipMember ? "btn" : "btn btn--pri"} >
                            {isVipMember ? `${getValueByKey(["Account_page", "Membership", "Already_Member"])} CVIP` : "ĐĂNG KÝ NGAY"}
                          </a>
                        ) : null}
                      </div>
                    </div>
                    <div className="block-memb-it block-memb-vip col is-desktop">
                      <Image width={1000} height={200} src="/assets/images/Loyalty_Program.webp" alt="Loyalty points"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}