import useContentGlobal from '@/hooks/useContentGlobal';
import { zeroFill } from '@/utils/format';
import { useEffect } from 'react';

type Props = {
  timeCountDown: number
  setIsTimeOut: (value: boolean) => void
}

export const CountDownBooking = ({ timeCountDown, setIsTimeOut }: Props) => {
  const { getValueByKey } = useContentGlobal();

  // Count down
  useEffect(() => {
    if (timeCountDown > 0) {
      const timer = setInterval(() => {
        let timeLeft = timeCountDown - new Date().getTime()
        if (timeLeft < 0) {
          setIsTimeOut(true)
          clearInterval(timer)
          return
        }
        let minutes = Math.floor(timeLeft / 1000 / 60)
        let seconds = Math.floor(timeLeft / 1000 % 60)
        const timerInterval = document.getElementById('timerCheckout')
        if (timerInterval) {
          if (minutes < 0 && seconds < 0) {
            clearInterval(timer)
            setIsTimeOut(true)
            timerInterval.innerHTML = `00:00`
            sessionStorage.removeItem('timerSeat')
          }
          timerInterval.innerHTML = `${zeroFill(minutes)}: ${zeroFill(seconds)} `
        }
      }, 1000)
      return () => clearInterval(timer)
    }
    return
  }, [setIsTimeOut, timeCountDown])
  return (
    <>
      <div className="bill-coundown-custom">
        <p className="txt">
          {getValueByKey("Ticket_Holding_Time")}:
        </p>
        <div className="bill-coundown !w-[68px]">
          <div className="bill-time">
            <p id='timerCheckout'>00:00</p>
          </div>
        </div>
      </div>
    </>
  )
}