import { ChangeEvent, FC, useState } from 'react';

import { handleEncode, SECRET_KEY } from '@/helper/encode';
import useDataUser from '@/hooks/useDataUser';
import { RootState, useAppDispatch } from '@/store/store';
import { setUserInfo } from '@/store/userSlice';
import { TUser } from '@/types/user';

import { PopupNoti } from '@/components/screen/common/PopupNoti';
import useSavaSessionKey from '@/hooks/useSavaSessionKey';
import useStatePopupNotify from '@/hooks/useStatePopupNotify';
import { sendLog } from '@/services/sendLog';

import { useSelector } from 'react-redux';
import { setUserTempInfo } from '@/store/userTemp';
import useContentGlobal from '@/hooks/useContentGlobal';

const MAX_FILE_SIZE = 1 * 1024 * 1024;

const AvatarChangeButton: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const userInfo = useDataUser();
  const { session_key } = useSavaSessionKey();
  const { getValueByKey } = useContentGlobal();

  const { popupNotify, handleOpenNotify, handleClosedNotify } = useStatePopupNotify();

  const userTemp = useSelector(
    (state: RootState) => state.userTemp
  )

  const dispatch = useAppDispatch();

  const cutUrl = (inputUrl: string): string => {
    if (inputUrl.includes("/graphql")) return inputUrl.replace("/graphql", "");
    return inputUrl;
  }

  const handleCallApiEdit = async (input: any) => {
    return await fetch("/api/member/edit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    }).then(res => res.json()).catch((err) => {
      sendLog({
        action: "edit_member",
        page_name: "AccountProfilePage",
        page_url: "/account/profile",
        error_message: JSON.stringify(err),
        session_key: session_key,
        status: "fail",
        information: "Edit member fail",
        request_json: JSON.stringify({ input })
      })
    })
  }

  const handleSaveData = (newDataUser: TUser) => {
    if (userTemp?.userInfo) {
      dispatch(setUserTempInfo({
        userInfo: handleEncode(newDataUser, SECRET_KEY)
      }));
      return;
    }

    dispatch(setUserInfo({
      userInfo: handleEncode(newDataUser, SECRET_KEY)
    }));
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];

      if (selectedFile === undefined || !selectedFile.type.startsWith('image/')) {
        return handleOpenNotify(getValueByKey(["Message", "Error", "File_Empty"]) ?? 'Vui lòng chọn một file hình ảnh!');
      }

      if (selectedFile?.size > MAX_FILE_SIZE) return handleOpenNotify(
        getValueByKey(["Message", "Error", "File_Size"])
          ? `${getValueByKey(["Message", "Error", "File_Size"])}1MB!`
          : 'Vui lòng chọn hình không vượt quá 1MB!'
      );

      setIsLoading(true);
      const formData = new FormData;
      formData.append("avatar", selectedFile);

      const res: any = await fetch(`${cutUrl(process.env.NEXT_PUBLIC_API_URL_MAGENTO as string)}/customer/upload/avatar`, {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      }).then(response => response.json()).catch((err) => {
        sendLog({
          action: "upload_avatar",
          page_name: "AccountProfilePage",
          page_url: "/account/profile",
          error_message: JSON.stringify(err),
          session_key: session_key,
          status: "fail",
          information: "Upload avatar fail",
          request_json: JSON.stringify(formData)
        })
      });

      if (res?.url) {
        try {
          const resApiEdit = await handleCallApiEdit({
            account: userInfo.account,
            password: userInfo.password,
            name: userInfo.name,
            email: userInfo.email,
            phone: userInfo.phone,
            gender: userInfo.gender,
            birthday: userInfo.birthday,
            address: userInfo.address,
            identification: userInfo.identification,
            image: res.url,
          });

          const message = resApiEdit?.respond?.message ?? "";

          if (message === "1") {
            handleSaveData({ ...userInfo, image: res.url });
            return handleOpenNotify(getValueByKey(["Message", "Success", "Upload_Avatar"]) ?? "Thay đổi ảnh đại diện thành công");
          }
        } catch (error) {
          return handleOpenNotify(getValueByKey(["Message", "Error", "Had_Error"]) ?? 'Có lỗi xảy ra!');
        } finally {
          setIsLoading(false);
        }
      }

      setIsLoading(false);
      return handleOpenNotify(getValueByKey(["Message", "Error", "Upload_Avatar"]) ?? 'Thất bại! File quá lớn hoặc hình ảnh ko hợp lệ.');
    }
  };

  return (
    <div>
      <PopupNoti isOpen={popupNotify.isOpen} handleClose={handleClosedNotify} content={popupNotify.message} />
      <label
        className="about-user-action"
        style={isLoading ? { color: "#9C9C9C", cursor: "wait" } : {}}
        htmlFor="avatarInput">
        {getValueByKey("Change_Avatar")}
      </label>
      <input
        type="file"
        id="avatarInput"
        style={{ display: 'none' }}
        disabled={isLoading}
        accept="image/*"
        onChange={handleFileChange} />
    </div>
  );
};

export default AvatarChangeButton;