/* eslint-disable @next/next/no-img-element */
import { swiperConfig } from '@/utils/SwiperConfig';
import { MovieSection } from '../movie';
import { useEffect } from 'react';

type TProps = {
  movies: TMovie[]
}

export const MovieHome: React.FC<TProps> = ({ movies }) => {
  useEffect(() => {
    swiperConfig(".web-movie-list", {
      speed: 600,
      initialSlide: 0,
      centeredSlides: false,
      loop: false,
      spaceBetween: 0,
      effect: "slide",
      slidesPerView: "auto",
      slidesPerGroup: 4,
      breakpoints: {
        0:{
          slidesPerGroup: 2,
        },
        1200: {
          slidesPerGroup: 4,
        }
      }
    }, "bullets");
  }, [])
  
   return (
      <> 
       <MovieSection movies={movies} isShowMore={true} isShowMoreUrl={"/movie"}/>
      </>
   )
}