import { toggleLanguage } from "@/helper"
import { useEffect, useState } from "react"
/* eslint-disable @next/next/no-img-element */
type props = {
  ortherService: {
    ortherService_en: string
    ortherService_vn: string
  }
}
export const ServiceHome = ({ ortherService }: props) => {
  const [isClient, setIsClient] = useState(false)
  useEffect(()=>{
    setIsClient(true)
  },[])
  return (
    <div dangerouslySetInnerHTML={{ __html:  isClient ? toggleLanguage(ortherService, "ortherService") : ortherService?.ortherService_vn }}/>
  )
}