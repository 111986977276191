import React, { Fragment } from 'react'
import { PopcornItem } from './PopcornItem'

export const PopcornList = ({ comboList }: { comboList :TComboItem[]}) => {
  return (
    <div className="combo-content">
      <div className="combo-list row">
        {comboList?.map((item: TComboItem) => {
          return <Fragment key={item?.id}>
            <PopcornItem data={item}/>
          </Fragment>
        })}

      </div>
    </div>
  )
}
