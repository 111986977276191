import React from 'react'

type Props = {
  isOpen: boolean,
  handleCanle: any,
  handleLogout: () => void
}

export const PopupConfirmLogout = (props: Props) => {

  // console.log(props.isOpen);
  return (
    <div className={props.isOpen ? "popup popup-logout open" : "popup popup-logout"}>
      <div className="popup-overlay" onClick={() => props.handleCanle()}>
      </div>
      <div className="popup-main">
        <div className="popup-main-wrapper">
          <div className="popup-over">
            <div className="popup-wrapper">
              <div className="popup-logout-wr">
                <p className="popup-logout-title">
                  Bạn có chắc chắn đăng xuất khỏi CINESTAR chứ ?
                </p>
                <div className="popup-logout-ctr">
                  <button className="btn btn--pri" onClick={props.handleLogout}>
                    <span className="txt">
                      ĐĂNG XUẤT
                    </span>
                  </button>
                  <button className="btn btn--outline" onClick={() => props.handleCanle()}>
                    <span className="txt">
                      HUỶ
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-close popupClose">
          <i className="fas fa-times icon"></i>
        </div>
      </div>
    </div>
  )
}