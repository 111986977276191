import InputRHF from '@/components/InputRHF'
import Link from 'next/link'
import { Controller } from "react-hook-form"
import useContentGlobal from '@/hooks/useContentGlobal'

type Props = {
  handleChangeCheckout: any,
  control: any,
  loading: boolean
}

export const CheckoutStep1 = (props: Props) => {
  const { getValueByKey } = useContentGlobal();

  return (
    <div className="form-cus">
      <form className="form" >
        <div className="form-list">
          <div className="form-it">
            <InputRHF control={props?.control} name='name' label={getValueByKey("Name")} required placeholder={getValueByKey("Full_Name")} />
          </div>
          <div className="form-it">
            <InputRHF control={props?.control} name='phone' label={getValueByKey("Phone_Number")} required placeholder={getValueByKey("Phone_Number")} />
          </div>
          <div className="form-it">
            <InputRHF control={props?.control} name='email' label='Email' required placeholder="Email" />
          </div>
          <div className="form-it">
            <div className="checkbox">
              <Controller
                name='right_age'
                control={props?.control}
                render={({
                  field: { value, onChange },
                  formState: { },
                }) => (
                  <input className="check checkbox-input" checked={value} type="checkbox" id="checkbox-1" onChange={(e) => onChange(e.target.checked)} />
                )} />
              <label className="checkbox-label checkbox-label-custom" htmlFor="checkbox-1">
                <span className="checkbox-label-custom-sqr"></span>
                {getValueByKey(["Checkout", "Rules_1"])}</label>
            </div>
            <div className="checkbox">
              <Controller
                name='policy'
                control={props?.control}
                render={({
                  field: { value, onChange },
                  formState: { },
                }) => (
                  <input className="check checkbox-input" checked={value} type="checkbox" id="checkbox-2" onChange={(e) => onChange(e.target.checked)} />

                )} />
              <label className="checkbox-label checkbox-label-custom" htmlFor="checkbox-2">
                <span className="checkbox-label-custom-sqr"></span>
                {getValueByKey(["Checkout", "Rules_2"])}
                <Link href={"/dieu-khoan-chung"} className="link" target={"_blank"}>
                  {getValueByKey(["Checkout", "Rules_3"])}
                </Link>
              </label>
            </div>
          </div>
          <div className="form-it">
            <div className="btn btn-submit btn--pri" onClick={props.handleChangeCheckout}>
              {props?.loading ?
                <i className="fas fa-spinner fa-spin"></i>
                : getValueByKey("Continue")
              }
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
