/* eslint-disable @next/next/no-img-element */
import { toggleLanguage } from '@/helper'

import Link from 'next/link'
import useContentGlobal from '@/hooks/useContentGlobal'
import Image from 'next/image'

export const CinemaItem = ({ cinima }: { cinima: TCinima }) => {
  const { getValueByKey } = useContentGlobal();



  return (
    <div className="cinema-theater-item col col-4 collapseItem" data-aos="fade-up" key={cinima?.id}>
      <div className="cinema-rental-custom">
        <div className="cinema-rental-remake-img">
          <Image
            src={`${cinima?.image || "/assets/images/img-cinema-rental-1.jpg"}`}
            alt=""
            width={500}
            height={500}
            className='!top-1/2 !left-1/2'
            loading='eager'
            priority
          />
        </div>
        <div className="content-main getHeight">
          <div className="address-box">
            <div className="cinema-rental-heading collapseHead">
              <Link href={`/book-tickets/${cinima?.id}`} className="link">
                <h4 className="sub-tittle">{toggleLanguage(cinima, "name") || ""}</h4>
              </Link>
              <i className="far fa-angle-down"></i>
            </div>
            <div className="cinema-rental-bodys">
              <div className="cinema-rental-footer">
                <ul className="list">

                  {/* {cinima?.description && */}
                  <li className="item">
                    <Link href={`/book-tickets/${cinima?.id}`} className="link">
                      <span className="ic">
                        <img src="/assets/images/ic-branch-room.svg" alt="" />
                      </span>
                      <span className="txt">{cinima?.infor_cinema || ""}</span>
                    </Link>
                  </li>
                  {/* } */}
                  {/* {cinima?.address && */}
                  <li className="item">
                    <Link className="link" href={cinima?.maps_url || ""} target='_blank'>
                      <span className="ic">
                        <img src="/assets/images/ic-branch-map.svg" alt="" />
                      </span>
                      <span className="txt">{cinima?.address || ""}</span>
                    </Link>
                  </li>
                  {/* } */}
                  <Link href={`/book-tickets/${cinima?.id}`} className="btn btn--pri btn-booking !mt-[4px] !mb-[10px]" >
                    {getValueByKey("Buy_Tickets")}
                  </Link>
                  {/* <div className="txt collapseBody">{cinima?.description || ""}</div> */}

                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
