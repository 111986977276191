import useContentGlobal from '@/hooks/useContentGlobal';
import { swiperConfig } from '@/utils/SwiperConfig';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';


export const BannerPromotionHome = ({ listPromotion }: { listPromotion: any }) => {
  const { getValueByKey } = useContentGlobal();

  useEffect(() => {
    const isMobile = () => window.innerWidth <= 700;
    const swiperConfigObject: any = {
      speed: 600,
      initialSlide: 0,
      centeredSlides: false,
      spaceBetween: 0,
      effect: "slide",
      slidesPerView: "auto",
    };

    if (isMobile()) {
      // swiperConfigObject.autoplay = true;
      swiperConfigObject.loop = true;
    }
    swiperConfig(".km-bn-slider", swiperConfigObject, "bullets");

  }, [])
  if (!listPromotion?.listBannerPromotion?.length) return null
  return (
    <section className="sec-km">
      <div className="km ht custom-padding">
        <div className="container">
          <div data-aos="fade-up">
            <div className="km-bn-slider custom-height-img">
              <div className="swiper-container">
                <div className="swiper rows">
                  <div className="swiper-wrapper">
                    {listPromotion?.listBannerPromotion?.map((item: any) => {
                      return (
                        <div className="swiper-slide col" key={item?.banner_id}>
                          <Link href={item?.link ?? "#"} className="km-bn-img" aria-label={item?.link}>
                            <Image
                              src={item?.media} alt="Banner promotion"
                              fill
                              className='!top-1/2 !left-1/2'
                              loading='eager'
                              fetchPriority='high'
                              priority
                              sizes="(max-width: 426px) 400px, (max-width: 768px) 750px, 100vw"
                              quality={50}
                            />
                            {item?.type == "movie" && <span className="btn btn--pri km-bn-btn">
                              <span className="txt" suppressHydrationWarning>{getValueByKey("Buy_Tickets_Now") ?? "Đặt vé ngay"}</span>
                            </span>}
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="button-swiper is-desktop">
                  <div className="arrow-btn-prev swiper-prev">
                    <i className="fa-solid fa-chevron-left"></i>
                  </div>
                  <div className="arrow-btn-next swiper-next">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination --second"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}