import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'
import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { post } = new BaseAPI<TBooking & TFilterParams>('v2/Booking')



export const resendBookingInfo = gql`
	mutation resendBookingInformation($input: BookingInformationInput!) {
		resendBookingInformation(input: $input) {
			status
		}
	}
`
export const booking = {
	create: (data: any) => post<TBooking[]>(``, data),
	resendBookingInfo: (data: { barcode: string; id_server: string }) =>
		graphQLClient.request(resendBookingInfo, {
			input: { barcode: data.barcode, id_server: data.id_server }
		})
}